* {
  margin: 0;
  padding: 0;
}

html {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 12px;
  font-weight: 300;
  color: #ccc;
  background-color: #101010; 
  background-image: linear-gradient(#101010 0, #101010 8px, #0c0c0c 8px, #0c0c0c 16px);
  background-size: 100% 16px;
  background-position: 0 -4px;
  height: 100%;
}

body {
  padding: 16px;
  box-sizing: border-box;
  height: 100%;
}

input, button {
  outline: 0;
}

input[type="text"], input:not([type]) {
  border: 1px solid transparent;
  background-color: transparent;
  color: inherit;
  font: inherit;
}

input[type="text"]:not(:disabled):not(:focus):hover,
input:not([type]):not(:disabled):not(:focus):hover,
{
  border-color: #444;
}

input[type="text"]:focus,
input:not([type]):focus,
{
  color: #ccc;
  border-color: #666;
  background-color: #111;
  box-shadow:
    -1px -1px 0.5px rgba(255, 255, 255, 0.1) inset,
    1px 1px 2px rgba(0, 0, 0, 1.0) inset;
}

input[type="text"].ng-invalid,
input:not([type]).ng-invalid,
{
  border-color: #c44;
}

input[type="submit"] {
  width: 100%;
  cursor: pointer;
  border: 0;
  outline: 0;
}

ul, ol, li {
  list-style-type: none;
}

strong {
  font-weight: normal;
  color: #eee;
}

.errorbar {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  font-size: 16px;
  line-height: 24px;
  z-index: 1000;
  margin-bottom: 16px;
  border-radius: 16px;
  text-align: center;
}

.errorbar-panic {
  background: #f44;
  color: #fff;
}

.errorbar-warning {
  background: #db4;
  color: #333;
}

.github {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  width: 149px;
  height: 149px;
  background-image: url(https://camo.githubusercontent.com/38ef81f8aca64bb9a64448d0d70f1308ef5341ab/68747470733a2f2f73332e616d617a6f6e6177732e636f6d2f6769746875622f726962626f6e732f666f726b6d655f72696768745f6461726b626c75655f3132313632312e706e67);
  text-indent: -9999px;
  opacity: 0.3;
  background-position: 10px -10px;
  background-repeat: no-repeat;
  transition: background-position 0.05s linear, opacity 0.05s linear;
}

.github:hover {
  opacity: 0.8;
  background-position: 0 0;
  z-index: 1;
  transition: background-position 0.2 linear, opacity 0.2 linear;
}

.github:active {
  opacity: 1.0;
}

.main {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  padding: 8px;
  position: relative;
  box-sizing: border-box;
  width: 960px;
  height: 100%;
  min-height: 320px;
  margin: 0 auto;
  border-radius: 16px;
  background: #141218;
  box-shadow:
    0 0 8px #000,
    2px 4px 8px #000,
    1px 2px 4px rgba(255, 255, 255, 0.05) inset,
    -1px -2px 4px rgba(0, 0, 0, 0.1) inset;
}

.topbar {
  display: -webkit-flex;
  width: 100%;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  height: 47px;
  margin-bottom: 8px;
  -webkit-flex-grow: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}

.content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

.pane {
  position: relative;
  background: #040404;
  box-shadow:
    0.5px 1px 1px rgba(0, 0, 0, 1.0) inset,
    -0.5px -1px 1px rgba(255, 255, 255, 0.2) inset,
    0 0 2px rgba(0, 0, 0, 1.0);
  padding: 8px;
  box-sizing: border-box;
}

.pane-top-right {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.pane-bottom-left {
  border-bottom-left-radius: 8px;
}

.pane-bottom-right {
  border-bottom-right-radius: 8px;
}

.vertical-scroll {
  overflow-y: auto;
}

.column-left {
  width: 160px;
  margin-right: 8px;
}

.column-right {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

.titlepane {
  margin-left: -8px;
  margin-top: -8px;
  padding-left: 8px;
  padding-top: 8px;
  background: #000;
  border-top-left-radius: 16px;
  box-shadow:
    1px 2px 4px rgba(255, 255, 255, 0.2) inset,
    -1px -2px 4px rgba(255, 255, 255, 0.05) inset,
    0.5px 1px 0.5px rgba(255, 255, 255, 0.3) inset,
    -0.5px -1px 2px rgba(0, 0, 0, 1.0) inset,
    -0.5px -1px 0.5px rgba(255, 255, 255, 0.0) inset,
    0 0 2px rgba(0, 0, 0, 1.0);
}

h1 {
  color: #fff;
  font-family: Chango, sans-serif;
  text-shadow: 0 0 4px rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  padding-left: 6px;
  font-size: 32px;
  line-height: 39px;
}

h1 a {
  color: inherit;
  text-decoration: inherit;
}

.credits {
  font-family: Chango, sans-serif;
  font-size: 9px;
  line-height: 9px;
  margin: -4px 4px 0;
  text-align: right;
  color: #444;
}

.credits a {
  color: inherit;
  text-decoration: none;
  transition: color 0.1s linear;
}

.credits a:hover {
  text-decoration: underline;
  color: #666;
  transition: none;
}

.credits a:active {
  color: #555;
}

.playbackpane {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
  align-items: stretch;
  padding: 2px;
}

.analyser {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  cursor: pointer;
}

.analyser-disabled {
  background-image: linear-gradient(#080808 0%, #101010 30%, #080808 70%, #000000 100%);
  box-shadow:
    0 0 2px rgba(255, 255, 255, 0.1) inset;
}

.shiny {
  background-image: linear-gradient(#181830 0%, #5050a0 100%);
  box-shadow:
    0 0 2px rgba(224, 224, 255, 0.3) inset,
    0 0 8px rgba(0, 0, 0, 0.8) inset,
    0 8px 4px rgba(224, 224, 255, 0.6) inset;
  color: rgba(255, 255, 255, 0.7);
  text-shadow:
    -1px -2px 4px rgba(0, 0, 0, 0.5),
    1px 2px 4px rgba(128, 128, 255, 0.5);
  overflow: hidden;
  transition: all 0.03s linear;
}

.shiny:hover {
  color: rgba(240, 240, 255, 1.0);
  text-shadow:
    -1px -2px 4px rgba(0, 0, 0, 0.5),
    1px 2px 4px rgba(128, 128, 255, 1.0);
}

.shiny-display {
  font-family: Chango, sans-serif;
  font-size: 24px;
  height: 32px;
  border-radius: 8px;
}

.shiny-checked {
  background-image: linear-gradient(#181830 0%, #303060 100%);
  color: rgba(255, 255, 255, 0.9);
  box-shadow:
    -1px -1px 0px rgba(224, 224, 255, 0.2) inset,
    1px 1px 8px rgba(0, 0, 0, 1.0) inset,
    0 6px 4px rgba(192, 192, 255, 0.4) inset;
}

.shiny:active {
  color: rgba(255, 255, 255, 0.6);
  box-shadow:
    -1px -1px 0px rgba(224, 224, 255, 0.2) inset,
    1px 1px 8px rgba(0, 0, 0, 1.0) inset,
    0 4px 4px rgba(192, 192, 255, 0.4) inset;
}

.shinycontent {
  position: relative;
}

.shiny-checked .shinycontent,
.shiny:active .shinycontent {
  left: 1px;
  top: 1px;
}

.autoplay {
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  width: 16px;
  padding: 0 8px;
  border: none;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 43px;
}

.autoplay input {
  position: absolute;
  visibility: hidden;
}

.autoplay .shinycontent {
  position: relative;
  display: block;
  margin-left: -101px;
  width: 200px;
  text-align: center;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.playstop {
  cursor: pointer;
  width: 76px;
  border: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 32px;
  line-height: 32px;
}

.playicon {
  display: inline-block;
  vertical-align: center;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 21px;
  border-color: transparent;
  border-left-color: #ddd;
  position: relative;
  left: 10.5px;
}

.shiny:hover .playicon {
  border-left-color: rgba(240, 240, 255, 1.0);
}

.filespane {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}

.button {
  display: block;
  width: 100%;
  height: 16px;
  border: 0;
  cursor: pointer;
  
  font-family: inherit;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-align: center;

  background-color: #111;
  color: #888;
  box-shadow:
    2px 2px 4px rgba(255, 255, 255, 0.05) inset,
    -2px -2px 4px rgba(0, 0, 0, 0.4) inset;

  transition: color 0.1s linear, background-color 0.1s linear;

  -webkit-flex-grow: 1;
  flex-grow: 1;
}

.button-checked {
  background-color: #222;
  color: #aaa;
  box-shadow:
    -2px -2px 4px rgba(255, 255, 255, 0.05) inset,
    2px 2px 4px rgba(0, 0, 0, 0.4) inset;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
}

.button:hover:not(:disabled) {
  color: #ccc;
  background-color: #222;
  transition: none;
}

.button:active:not(:disabled) {
  background-color: #181818;
  box-shadow:
    -1px -1px 2px rgba(255, 255, 255, 0.05) inset,
    1px 1px 2px rgba(0, 0, 0, 0.4) inset;
}

.button:active:not(:disabled) > span,
.button-checked > span {
  position: relative;
  top: 1px;
  left: 1px;
}

.button:disabled {
  color: #444;
  box-shadow: none;
  cursor: inherit;
}

.button-tool {
  -webkit-flex-basis: 0;
  flex-basis: 0;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

.offsetparent {
  position: relative;
}

.toolbar {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  margin-bottom: 8px;
}

.linkbox {
  position: absolute;
  top: 16px;
  left: 0;
  width: 128px;
  z-index: 10;
}

.button-createnew {
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  overflow: hidden;
}

.button-createnew input {
  position: absolute;
  visibility: hidden;
}

.button-createnew > span {
  display: block;
  white-space: nowrap;
  width: 200px;
  margin: 0 -99px 0 -101px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.presets {
  padding-left: 16px;
  width: 100%;
}

.button-preset {
  width: 100%;
}

.history {
  -webkit-flex: 1;
  flex: 1;
  align-items: stretch;
  overflow: auto;
}

.sound {
  position: relative;
  transition: color 0.1s linear, background-color 0.1s linear;
}

.sound:hover {
  color: #eee;
  transition: none;
}

.sound:active {
  color: #ccc;
}

.sound-current {
  background: #222;
  color: #eee;
}

.soundname {
  box-sizing: border-box;
  width: 100%;
  height: 16px;
}

.soundnamesensor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.iconbutton {
  display: block;
  width: 15px;
  height: 15px;
  background-image: url(../images/sprites.png);
  border: 0;
  background-color: transparent;
  opacity: 0.4;
  transition: opacity 0.1s linear;
}

.iconbutton:not(:disabled) {
  cursor: pointer;
}

.iconbutton:not(:disabled):hover {
  opacity: 0.7;
  transition: none;
}

.iconbutton:not(:disabled):active {
  opacity: 0.6;
}

.iconbutton:disabled {
  opacity: 0.1;
}

.iconbutton-delete {
  background-position: 0px -15px;
}

.deletebutton {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
}

.sound:hover .deletebutton {
  display: block;
}

.statusbarpane {
  padding-bottom: 0;
  background-image: linear-gradient(to top, #0b0b0b 16px, transparent 16px);
}

.statusbar {
  margin-top: 8px;
  height: 16px;
  line-height: 16px;
  color: #444;
  font-size: 11px;
}

.statusbar-right {
  text-align: right;
}

.statusbar a {
  color: #555;
  text-decoration: none;
  transition: color 0.1s linear;
}

.statusbar a:hover {
  color: #aaa;
  text-decoration: underline;
}

.statusbar a:active {
  color: #888;
  text-decoration: underline;
}

.mainpane {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.canvas {
  display: block;
  box-sizing: border-box;
  width: 100%;
  background-color: #111;
  box-shadow:
    0.5px 1px 0.5px rgba(255, 255, 255, 0.2),
    1px 2px 2px rgba(0, 0, 0, 1.0);
}

.canvas-waveshape {
  height: 63px;
}

.canvas-small {
  height: 23px;
  margin-top: 4px;
  margin-bottom: 5px;
}

.parameters {
  display: -webkit-flex;
  display: flex;
  margin-top: 8px;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 0;
  flex-basis: 0;
}

.parameters-column {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-basis: 0;
  flex-basis: 0;
  padding-right: 16px;
  border-right: 1px solid #080808;
  margin-right: 16px;
}

.parameters-column:last-child {
  padding-right: 0;
  border-right: 0;
  margin-right: 0;
}

h2 {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 100%;
  padding-top: 16px;
  line-height: 16px;
  box-sizing: border-box;
  color: #555;
  text-shadow: 0 0 1px #333;
  border-bottom: 1px solid #111;
}

h2:first-child {
  padding-top: 0;
}

.amplitude {
  color: #d66;
}

.pitch {
  color: #bb5;
}

.harmonics {
  color: #5b5;
}

.tone {
  color: #b6b;
}

.filters {
  color: #5ba;
}

.output {
  color: #57d;
}

.param {
  box-sizing: border-box;
  height: 16px;
  border-bottom: 1px solid #080808;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
}

.param-disabled {
  opacity: 0.5;
}

.paramlabel {
  box-sizing: border-box;
  width: 112px;
  text-align: right;
  padding-right: 8px;
}

.paramcontent {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  height: 16px;
  padding-right: 8px;
}

.parambuttons {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.iconbutton-lock {
  background-position: -30px -15px;
}

.iconbutton-lock-locked {
  background-position: -15px -15px;
  background-color: #555;
}

.iconbutton-reset {
  background-position: -45px -15px;
}

.paramcontrol {
  box-sizing: border-box;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  padding-right: 8px;
}

.paramvalue {
  box-sizing: border-box;
  width: 41px;
  text-align: right;
}

.paramunit {
  box-sizing: border-box;
  width: 16px;
  text-align: left;
  padding-left: 3px;
}

.customparamvalue {
  box-sizing: border-box;
  width: 57px; /* paramvalue + paramunit */
  text-align: right;
}

.waveforms {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.waveform {
  cursor: pointer;
  text-indent: -9999px;
  display: inline-block;
  width: 15px;
  height: 15px;
  background: url(../images/sprites.png);
  opacity: 0.7;
  transition: opacity 0.2s linear;
}

.waveform:hover {
  opacity: 1.0;
  transition: none;
}

.waveform.checked {
  background-color: #444;
}

.waveform-sine {
  background-position: 0 0;
}

.waveform-triangle {
  background-position: -15px 0;
}

.waveform-sawtooth {
  background-position: -30px 0;
}

.waveform-square {
  background-position: -45px 0;
}

.waveform-tangent {
  background-position: -60px 0;
}

.waveform-whistle {
  background-position: -75px 0;
}

.waveform-breaker {
  background-position: -90px 0;
}

.waveform-whitenoise {
  background-position: -105px 0;
}

.waveform-pinknoise {
  background-position: -120px 0;
}

.waveform-brownnoise {
  background-position: -135px 0;
}

.floatslider {
  display: block;
  -webkit-appearance: none;
  background-color: transparent;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0) 4px,
    #000 4px,
    #222 8px,
    #000 8px,
    #000 9px,
    rgba(0, 0, 0, 0) 9px
  );
  width: 100%;
  height: 13px;
  margin: 1px 0;
}

.floatslider::-moz-range-track {
  display: block;
  -moz-appearance: none;
  border: 0;
  background-color: transparent;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0) 4px,
    #000 4px,
    #222 8px,
    #000 8px,
    #000 9px,
    rgba(0, 0, 0, 0) 9px
  );
  width: 100%;
  height: 13px;
  margin: 1px 0;
}

.floatslider:not(:disabled) {
  cursor: pointer;
}

.floatslider::-moz-range-thumb {
  -moz-appearance: none;
  border: 0;
  background-color: #666;
  background-image: radial-gradient(circle, rgba(0, 0, 0, 0.3) 0%, transparent 100%);
  border-radius: 2px;
  width: 13px;
  height: 13px;
  box-shadow:
    1px 1px 0.5px rgba(255, 255, 255, 0.3) inset,
    -1px -1px 0.5px rgba(0, 0, 0, 0.5) inset;
  transition: background-color 0.2s linear;
}

.floatslider::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #666;
  background-image: radial-gradient(circle, rgba(0, 0, 0, 0.3) 0%, transparent 100%);
  border-radius: 2px;
  width: 13px;
  height: 13px;
  box-shadow:
    1px 1px 0.5px rgba(255, 255, 255, 0.3) inset,
    -1px -1px 0.5px rgba(0, 0, 0, 0.5) inset;
  transition: background-color 0.2s linear;
}

.floatslider::-webkit-slider-thumb:hover {
  background-color: #888;
  transition: none;
}

.floatslider::-webkit-slider-thumb:active {
  background-color: #555;
  box-shadow:
    1px 1px 0.5px rgba(255, 255, 255, 0.3) inset,
    -1px -1px 0.5px rgba(0, 0, 0, 0.5) inset;
}

.floatslider:disabled::-webkit-slider-thumb {
  display: none;
}

.booleanlabel input {
  display: none;
}

.booleanlabel {
  display: block;
  width: 13px;
  height: 13px;
  margin: 1px 0;
  color: #000;
  background-color: #666;
  background-image: radial-gradient(circle, rgba(0, 0, 0, 0.3) 0%, transparent 100%), url(../images/sprites.png);
  background-position: 0 0, -61px -16px;
  border-radius: 2px;
  box-shadow:
    1px 1px 0.5px rgba(255, 255, 255, 0.3) inset,
    -1px -1px 0.5px rgba(0, 0, 0, 0.5) inset;
  cursor: pointer;
  transition: background-color 0.2s linear;
}

.booleanlabel:hover {
  background-color: #888;
  transition: none;
}

.booleanlabel:active {
  background-color: #555;
  box-shadow:
    1px 1px 0.5px rgba(255, 255, 255, 0.3) inset,
    -1px -1px 0.5px rgba(0, 0, 0, 0.5) inset;
}

.booleanlabel-checked {
  background-position: 0 0, -76px -16px;
}

.booleanlabel.booleanlabel-disabled {
  background-color: #666;
  box-shadow:
    1px 1px 0.5px rgba(255, 255, 255, 0.3) inset,
    -1px -1px 0.5px rgba(0, 0, 0, 0.5) inset;
  cursor: inherit;
}

.floattext {
  box-sizing: border-box;
  width: 100%;
  text-align: right;
  height: 17px;
}

.paramdescription {
  margin-top: 8px;
  border-top: 1px solid #080808;
  padding-top: 7px;
}

[ng\:cloak], [ng-cloak], .ng-cloak {
  display: none !important;
}
